.dnt__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.dnt__brand div {
    flex: 1;
    max-width: 75px;
    min-width: 120px;
    margin: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dnt__brand img {
    width: 35%;
    border-radius: 100%;
}

.dnt__brand img:hover {
    border: 3px solid #00c9dc;
    transform: scale(110%);
    cursor: pointer;
}

@media only screen and (max-width:512px) {
    .dnt__brand img {
        display: flex;
        clear: both;
    }



  }